import React from "react"
import { Box, Text, SimpleGrid, Image, Heading } from "@chakra-ui/core"
import Button from "../Button"
import SectionTitle from "../SectionTitle"
import DotsImage from "../../../images/dots.svg"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

interface IasSectionCardProps {
  title: string
  text: string
  image: any
}

const IasSectionCard = (props: IasSectionCardProps) => {
  return (
    <Box
      mx="auto"
      boxShadow="rgba(0, 0, 0, 0.08) 0px 6px 10px"
      textAlign="center"
    >
      <Img fluid={props.image} alt="classroom image" />
      <Box px={1}>
        <Heading fontSize="xl" my={4}>
          {props.title}
        </Heading>
        <Text fontSize="sm" mx={2} color="#717171">
          {props.text}
        </Text>
        <Link to="/physicalCourse">
          <Button
            mt={4}
            mb={5}
            bg="#18A818"
            color="#fff"
            borderRadius={"0.5em"}
          >
            மேலும் அறிக
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

const IasSection = () => {
  const data = useStaticQuery(graphql`
    query {
      upscEmblem: file(relativePath: { eq: "UPSCwithEmblem.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classRoom: file(relativePath: { eq: "classRoomImage.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box overflowX="hidden" position="relative">
      <Box display={["none", null, "block"]}>
        <Image src={DotsImage} position="absolute" top={"10%"} left={"-2%"} />
        <Image
          src={DotsImage}
          position="absolute"
          top={"30%"}
          right={"1%"}
          transform="rotate(90deg)"
        />
      </Box>
      <Box mt={[5, null, 10]} mb={[5, 10, 16]}>
        <SectionTitle title="ஐ.ஏ.எஸ்" />
      </Box>
      <Box mt={2} mb={10} mx="auto" maxW="200px">
        <Img fluid={data.upscEmblem.childImageSharp.fluid} alt="upsc" />
      </Box>
      <SimpleGrid
        my={5}
        px={5}
        mx="auto"
        spacing={12}
        columns={[1, null, 2, 3]}
        maxW="950px"
      >
        <IasSectionCard
          title="நேரடிப்பயிற்சி"
          text="ரவித்தது.1963 ஆம் ஆண்டுஆம் ஆண்டு முதல் 1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆ"
          image={data.classRoom.childImageSharp.fluid}
        />
        <IasSectionCard
          title="நேரடிப்பயிற்சி"
          text="ரவித்தது.1963 ஆம் ஆண்டுஆம் ஆண்டு முதல் 1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆ"
          image={data.classRoom.childImageSharp.fluid}
        />
        <IasSectionCard
          title="நேரடிப்பயிற்சி"
          text="ரவித்தது.1963 ஆம் ஆண்டுஆம் ஆண்டு முதல் 1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆ"
          image={data.classRoom.childImageSharp.fluid}
        />
        <IasSectionCard
          title="ஓன்லைன் பயிற்சி"
          text="ரவித்தது.1963 ஆம் ஆண்டுஆம் ஆண்டு முதல் 1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆ"
          image={data.classRoom.childImageSharp.fluid}
        />
        <IasSectionCard
          title="நேரடிப்பயிற்சி"
          text="ரவித்தது.1963 ஆம் ஆண்டுஆம் ஆண்டு முதல் 1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆ"
          image={data.classRoom.childImageSharp.fluid}
        />
        <IasSectionCard
          title="ஓன்லைன் பயிற்சி"
          text="ரவித்தது.1963 ஆம் ஆண்டுஆம் ஆண்டு முதல் 1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆ"
          image={data.classRoom.childImageSharp.fluid}
        />
      </SimpleGrid>
    </Box>
  )
}
export default IasSection
