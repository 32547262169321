import React from "react"
import PageBanner from "../components/PageBanner"
import { Box } from "@chakra-ui/core"
import IasSection from "../components/Ias/IasSection"
import AboutIasSection from "../components/Ias/AboutIas"
import KelviBathilgalSection from "../components/Ias/KelviBathilgalSection"
import BestTeachingSection from "../components/Home/BestTeachingSection"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import RegisterSection from "../components/RegisterSection"

const Upsc = () => {
  return (
    <Box>
      <SEO title="UPSC" />
      <Layout>
        <PageBanner bannerText="ஆட்சித்தமிழுக்கு வாருங்கள் அரசு பணியாளராய் மாறுங்கள்" />
        <IasSection />
        <AboutIasSection />
        <Box mt={20} py={1} bg="#F8F8F8">
          <BestTeachingSection />
        </Box>
        <KelviBathilgalSection />
        <RegisterSection />
        {/* <BooksSection /> */}
      </Layout>
    </Box>
  )
}
export default Upsc
